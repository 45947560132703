export class AppImage {
  static logo100 = "assets/img/logo100.png";
  static unknownImage = "assets/img/unknown.svg";
  static errorImage = "assets/img/error.png";
  static notFoundImage = "assets/img/404.png";

  static usage = [
    {
      image: "assets/img/usage/pioneer.png",
      translation: "pioneer",
    },
    {
      image: "assets/img/usage/beachhead.png",
      translation: "beachhead",
    },
    {
      image: "assets/img/usage/nmsMessage.png",
      translation: "look too the monolith... the weapon is coming",
    },
    {
      image: "assets/img/usage/station.png",
      translation: "online error station status : nominal ai mode slumber",
    },
    {
      image: "assets/img/usage/eggSequencerTank.png",
      translation: "korvet 20 treat your egg to a fresh pair of genes",
    },
    {
      image: "assets/img/usage/eggSequencer.png",
      translation: "treat your egg to a fresh pair of genes",
    },
    {
      image: "assets/img/usage/goldenGekHelmet.png",
      translation: "season f 1st",
    },
    {
      image: "assets/img/usage/atlasRace.png",
      translation: "23 rd atlas race",
    },
    {
      image: "assets/img/usage/callNum.png",
      translation: "call 0 🥩🥐",
    },
    {
      image: "assets/img/usage/signPost.png",
      translation: "trade area loading bays zones 123",
    },
    {
      image: "assets/img/usage/entireAlphabet.png",
      translation: "0987654321qwertyuiopasdfghjklzxcvbnm,.?",
    },
  ];
}
